import React from 'react';
import { Topbar } from './Topbar';
import { HeroSection } from './HeroSection';
import { ExploreSection } from './ExploreSection';

function App() {
  return (
    <div>
      <Topbar />
      <HeroSection />
      <ExploreSection />
    </div>
  );
}

export default App;
